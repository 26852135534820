import React from 'react'
import Link from 'gatsby-link'

import './IconLink.css'

const IconLink = (props) => (
  <div className="icon-link">
    <img className="icon" src={props.icon} alt="" />
    <h3 className="center-text">{props.title}</h3>
    <Link className="button button-primary" to={props.link}>
      Learn More
    </Link>
  </div>
)

export default IconLink
